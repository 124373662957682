import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '@environments/environment';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SentryErrorHandler } from './sentry.error.handler';

// App
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { GuardModule } from '@shared/guards/guards.module';
import { ErrorsModule } from '@services/errors/errors.module';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { DeviceServiceModule } from '@ionServices/device/device.module';
import { IonicToasterModule } from '@ionServices/toaster/ionic-toaster.module';

// User services
import { AcademiesServiceModule } from '@services/academies/academies.module';
import { ForgotPasswordServiceModule } from '@services/forgot-password/forgot-password.module';
import { PoliciesServiceModule } from '@services/policies/policies.module';
import { ProfessorsServiceModule } from '@services/professors/professors.module';
import { UsersServiceModule } from '@services/users/users.module';
import { CategoriesServiceModule } from '@services/categories/categories.module';
import { ActivitiesServiceModule } from '@services/activities/activities.module';
import { SessionsServiceModule } from '@services/sessions/sessions.module';

// Services
import { AuthModule } from '@services/auth/auth.module';
import { ConfigsServiceModule } from '@services/config/config.module';
import { ModalServiceModule } from '@services/modal/modal.module';
import { ScreenServiceModule } from '@services/screen/screen.module';
import { BasicObservableModule } from '@services/basic-observable/basic-observable.module';
import { LocalStorageModule } from '@services/local-storage/local-storage.module';
import { SessionStorageModule } from '@services/session-storage/session-storage.module';
import { GeolocationServiceModule } from '@services/geolocation/geolocation-service.module';
import { GoogleMapsServiceModule } from '@services/google-maps/google-maps-service.module';

// TRANSLATIONS
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToolbarModule } from '@shared/components/toolbar/toolbar.module';

// Swiper
import { SwiperModule } from 'swiper/angular';

// Cookies Banner
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookiesBannerModule } from '@shared/components/cookies-banner/cookies-banner.module';
import { cookieConfig } from '@shared/components/cookies-banner/config';
import { VisitsServiceModule } from '@services/visits/visits.module';
import { ScheduleServiceModule } from '@services/schedule/schedule-service.module';
import { FooterModule } from '@shared/components/footer/footer.module';
import { NgxMaskModule } from 'ngx-mask';
import { CryptoServiceModule } from '@services/crypto/crypto.module';
import { ReservationsServiceModule } from '@services/reservations/reservations.module';
import { StripeServiceModule } from '@services/stripe/stripe.module';
import { FaqsServiceModule } from '@services/faqs/faqs.module';
import { MediumServiceModule } from '@services/medium/medium.module';
import { FavoritesServiceModule } from '@services/favorites/favorites.module';
import { ActivityCheckerModule } from '@services/activity-checker/activity-checker.module';
import { PressReleaseServiceModule } from '@services/press-release/press-release.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    SwiperModule,
    ToolbarModule,
    BrowserModule,
    CookiesBannerModule,
    FooterModule,
    FavoritesServiceModule.forRoot(),
    MediumServiceModule.forRoot(),
    StripeServiceModule.forRoot(),
    ReservationsServiceModule.forRoot(),
    CryptoServiceModule.forRoot(),
    NgxMaskModule.forRoot(),
    GoogleMapsServiceModule.forRoot(),
    GeolocationServiceModule.forRoot(),
    SessionsServiceModule.forRoot(),
    ActivitiesServiceModule.forRoot(),
    CategoriesServiceModule.forRoot(),
    AcademiesServiceModule.forRoot(),
    ProfessorsServiceModule.forRoot(),
    ScreenServiceModule.forRoot(),
    PoliciesServiceModule.forRoot(),
    IonicModule.forRoot(environment.ionic),
    ForgotPasswordServiceModule.forRoot(),
    ConfigsServiceModule.forRoot(),
    ModalServiceModule.forRoot(),
    ErrorsModule.forRoot(),
    UsersServiceModule.forRoot(),
    LocalStorageModule.forRoot(),
    SessionStorageModule.forRoot(),
    AuthModule.forRoot(),
    BasicObservableModule.forRoot(),
    IonicToasterModule.forRoot(),
    DeviceServiceModule.forRoot(),
    GuardModule.forRoot(),
    ActivityCheckerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    VisitsServiceModule.forRoot(),
    ScheduleServiceModule.forRoot(),
    FaqsServiceModule.forRoot(),
    PressReleaseServiceModule.forRoot(),
  ],
  providers: [
    Deeplinks,
    StatusBar,
    Facebook,
    SplashScreen,
    Device,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
