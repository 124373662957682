import { environmentBase } from './environment.base';

export const environment = {
  ...environmentBase,
  api: 'https://ubniompjdlgfkmfrnvndal.becubs.com/v1',
  apiKey: '1a8MQYR9R8uCv6eH2v6UNa',
  cookieDomain: 'becubs.com',
  stripe: {
    clientId: 'ca_NfB7qYiByzRU4FPQrP9RjRvtQMPgOclK',
    redirectionUrl: 'https://www.becubs.com/admin/academy/stripe',
    baseUrl: 'https://connect.stripe.com/oauth/authorize',
    stripeBaseParams: 'response_type=code&scope=read_write',
  },
  inactivityMaxMinutes: 45,
};
